import React, { useState, useEffect, useCallback } from 'react';
import { GoogleMap, Marker, InfoWindow } from '@react-google-maps/api';
import { Link } from 'react-router-dom';
import { generateProductSlug } from '../contexts/utils';

const mapStyles = [
  {
    featureType: 'poi',
    elementType: 'labels',
    stylers: [{ visibility: 'off' }], 
  },
  {
    stylers: [
      { saturation: -100 },  
      { lightness: 0 },
      { gamma: 1 },
    ],
  },
];

const defaultCenter = {
  lat: 51.509865,
  lng: -0.118092,
};

const defaultZoom = 13;

const BrowseMap = ({ listings }) => {
  const [selectedListing, setSelectedListing] = useState(null);
  const [map, setMap] = useState(null);

  useEffect(() => {
    const link = document.createElement('link');
    link.href = 'https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20,400,0,0';
    link.rel = 'stylesheet';
    document.head.appendChild(link);

    return () => {
      document.head.removeChild(link);
    };
  }, []);

  useEffect(() => {
    if (map) {
      if (listings.length > 1) {
        // If there are multiple listings, fit the bounds
        const bounds = new window.google.maps.LatLngBounds();
        listings.forEach((listing) => {
          const lat = parseFloat(listing.latitude);
          const lng = parseFloat(listing.longitude);
          if (!isNaN(lat) && !isNaN(lng)) {
            bounds.extend({ lat, lng });
          }
        });
        map.fitBounds(bounds);
      } else if (listings.length === 1) {
        // If there is only one listing, center the map and set a zoom level
        const lat = parseFloat(listings[0].latitude);
        const lng = parseFloat(listings[0].longitude);
        if (!isNaN(lat) && !isNaN(lng)) {
          map.setCenter({ lat, lng });
          map.setZoom(defaultZoom);  // Set to a zoom level that shows the city
        }
      }
    }
  }, [map, listings]);

  const handleMarkerClick = useCallback((listing) => {
    setSelectedListing(listing);
  }, []);

  if (!listings || listings.length === 0) {
    return <div></div>;
  }

  const markerIcon = {
    path: window.google.maps.SymbolPath.CIRCLE,
    fillColor: 'white',
    fillOpacity: 1,
    strokeWeight: 0,
    scale: 10,
    labelOrigin: new window.google.maps.Point(0, 0),
  };

  return (
    <GoogleMap
      mapContainerClassName="map-container"
      zoom={10}
      center={defaultCenter}
      options={{
        styles: mapStyles,  
        disableDefaultUI: true,  
        mapTypeControl: false,  
        streetViewControl: false,
        fullscreenControl: true,
      }}
      onLoad={(mapInstance) => {
        setMap(mapInstance);
        window.googleMapInstance = mapInstance;
      }}
      onClick={() => setSelectedListing(null)}
    >
      {listings.map((listing) => {
        const lat = parseFloat(listing.latitude);
        const lng = parseFloat(listing.longitude);

        if (!isNaN(lat) && !isNaN(lng)) {
          return (
            <Marker
              key={listing.id}
              position={{ lat, lng }}
              map={window.googleMapInstance}
              onClick={() => handleMarkerClick(listing)}
              icon={markerIcon}
              label={{
                text: '\ue56a',
                fontFamily: 'Material Symbols Outlined',
                color: 'red',
                backgroundColor: 'white',
                fontSize: '33px',
              }}
            />
          );
        } else {
          console.error(`Invalid coordinates for listing ${listing.id}:`, { lat, lng });
          return null;
        }
      })}

      {selectedListing && (
        <InfoWindow
          position={{
            lat: parseFloat(selectedListing.latitude),
            lng: parseFloat(selectedListing.longitude),
          }}
        >
          <div style={{ minWidth: '100px', maxWidth: '400px', textAlign: 'center' }}>
            <h3 style={{ margin: '0', fontSize: '16px', fontWeight: '600', color: '#333' }}>
              {selectedListing.service_name}
            </h3>
            <p style={{ fontSize: '15px', color: '#777', margin: '5px 0' }}>
              from £{selectedListing.price}/hour
            </p>
            <Link
              to={`/products/${generateProductSlug(selectedListing.service_name, selectedListing.id)}`}
              style={{ textDecoration: 'none', color: '#007bff', fontSize: '14px' }}
            >
              See Details
            </Link>
          </div>
        </InfoWindow>
      )}
    </GoogleMap>
  );
};

export default BrowseMap;