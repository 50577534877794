import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from './AuthContext';

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated, loading, profile, fetchUserProfile, currentUser } = useAuth();
  const location = useLocation();
  const [profileLoading, setProfileLoading] = useState(true);

  useEffect(() => {
    const fetchProfile = async () => {
      if (isAuthenticated && currentUser && !profile) {
        try {
          setProfileLoading(true);
          await fetchUserProfile(currentUser); // Use profile fetch logic from AuthContext
        } catch (error) {
          console.error('Error fetching user profile in ProtectedRoute:', error);
        } finally {
          setProfileLoading(false);
        }
      } else {
        setProfileLoading(false);
      }
    };

    fetchProfile();
  }, [isAuthenticated, currentUser, profile, fetchUserProfile]);

  if (loading || profileLoading) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (isAuthenticated && !profile) {
    return <Navigate to="/create-profile" replace />;
  }

  return children;
};

export default ProtectedRoute;