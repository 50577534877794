import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import config from '../contexts/config';
import { generateProductSlug } from '../contexts/utils';
import BrowseMap from '../components/BrowseMap';

const categoryImageMap = {
  "🧹 Cleaning": "/images/default/default_cleaning.avif",
  "🛠️ Handyman": "/images/default/default_handyman.avif",
  "📚 Tutoring": "/images/default/default_tutor.avif",
  "🐶 Pets": "/images/default/default_pets.avif",
  "🪴 Gardening": "/images/default/default_gardening.avif",
  "🚚 Moving": "/images/default/default_moving.avif",
  "🚗 Car Related": "/images/default/default_car.avif",
  "💻 IT Set Up": "/images/default/default_itsetup.avif",
  "💆‍♀️ Selfcare": "/images/default/default_selfcare.avif",
  "👶 Baby Sitting": "/images/default/default_babysitting.avif",
  "💡 Others": "/images/default/default_others.avif",
};

const Browse = ({ isMapLoaded }) => {
  const location = useLocation();
  const { selectedCategory: initialCategory } = location.state || {}; 
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [showMap, setShowMap] = useState(false);  
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [priceFilters, setPriceFilters] = useState({
    under20: false,
    under40: false,
    under60: false,
    above60: false,
  });
  const [selectedCategories, setSelectedCategories] = useState(initialCategory ? [initialCategory] : []);
  const [currentPage, setCurrentPage] = useState(1);
  const servicesPerPage = 40;
  const { userId } = useAuth();

  useEffect(() => {
    fetchPublicServices();
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const fetchPublicServices = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${config.API_URL}/services/public`);
      const sortedServices = response.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
      setServices(sortedServices);
    } catch (error) {
      console.error('Error fetching services:', error); 
      setError('An error occurred while fetching services. Please try refreshing the page.');
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const handlePriceFilterChange = (filter) => {
    setPriceFilters((prev) => ({
      ...prev,
      [filter]: !prev[filter],
    }));
  };

  const handleCategoryFilterChange = (category) => {
    setSelectedCategories((prev) =>
      prev.includes(category)
        ? prev.filter((c) => c !== category)
        : [...prev, category]
    );
  };

  const filteredServices = services.filter((service) => {
    const matchesSearchQuery = service.service_name.toLowerCase().includes(searchQuery.toLowerCase());
    const matchesPriceFilter =
      (priceFilters.under20 && service.price < 20) ||
      (priceFilters.under40 && service.price < 40) ||
      (priceFilters.under60 && service.price < 60) ||
      (priceFilters.above60 && service.price >= 60) ||
      (!priceFilters.under20 && !priceFilters.under40 && !priceFilters.under60 && !priceFilters.above60);

    const matchesCategoryFilter =
      selectedCategories.length === 0 || selectedCategories.includes(service.service_category);

    return matchesSearchQuery && matchesPriceFilter && matchesCategoryFilter;
  });

  const toggleMapView = () => {
    setShowMap(!showMap);  
  };

  const indexOfLastService = currentPage * servicesPerPage;
  const indexOfFirstService = indexOfLastService - servicesPerPage;
  const currentServices = filteredServices.slice(indexOfFirstService, indexOfLastService);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const renderProductCards = () => (
    <div className="product-section">
      {loading ? (
        <p>Loading services...</p>
      ) : error ? (
        <p>Error: {error}</p>
      ) : currentServices.length > 0 ? (
        <>
          <div className="product-container">
            {currentServices.map((service) => (
              <div key={service.id} className="product-card">
                <Link
                  to={`/products/${generateProductSlug(service.service_name, service.id)}`}
                  style={{ textDecoration: 'none', color: 'inherit' }}
                >
                  <div className="service-image-container">
                    <img
                      src={service.service_photos1 || categoryImageMap[service.service_category]}
                      alt={service.service_name}
                      className="service-image"
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = categoryImageMap[service.service_category] || '/images/listing_img.avif';
                      }}
                    />
                    {service.service_category && (
                      <div className="service-category-tag">
                        {service.service_category}
                      </div>
                    )}
                    {userId === service.user_id && (
                      <div className="owner-overlay">This is your listing</div>
                    )}
                  </div>
                  <div className="browse-service-info">
                    <h3 className="browse-service-name">{service.service_name}</h3>
                    <p className="browse-service-price">from £{Math.round(service.price)}/h</p>
                  </div>
                  <div className="browse-service-details">
                    <div className="browse-user-info">
                      <div className="browse-profile-picture">
                        {service.profile_picture ? (
                          <img
                            src={service.profile_picture}
                            alt={service.username}
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = ''; 
                            }}
                          />
                        ) : (
                          <span className="profile-initials">
                            {service.username ? service.username[0].toUpperCase() : '?'}
                          </span>
                        )}
                      </div>
                      <span className="user-name">{service.username}</span>
                    </div>
                    <p className="service-area">
                      {service.service_area.replace(/, UK$/, '')}
                    </p>
                  </div>
                </Link>
              </div>
            ))}
          </div>
          <div className="pagination">
            {Array.from({ length: Math.ceil(filteredServices.length / servicesPerPage) }, (_, i) => (
              <button key={i} onClick={() => paginate(i + 1)}>
                {i + 1}
              </button>
            ))}
          </div>
        </>
      ) : (
        <div className="no-products-message">No services available.</div>
      )}
    </div>
  );

  return (
    <div className="browse-container">
      <div className="filter-sidebar">
        {!isMobile && ( 
          <button
            className={`toggle-map-btn ${showMap ? 'map-active' : ''}`}
            onClick={toggleMapView}
          >
            <span className="button-text">{showMap ? 'Back to services' : 'Map view'}</span>
          </button>
        )}
        <input
          type="text"
          placeholder="Search services"
          value={searchQuery}
          onChange={handleSearch}
        />
        <div className="price-filter">
          <h3>Price range by hour</h3>
          <div className="price-option">
            <input
              type="checkbox"
              id="under20"
              checked={priceFilters.under20}
              onChange={() => handlePriceFilterChange('under20')}
            />
            <label htmlFor="under20">Under £20</label>
          </div>
          <div className="price-option">
            <input
              type="checkbox"
              id="under40"
              checked={priceFilters.under40}
              onChange={() => handlePriceFilterChange('under40')}
            />
            <label htmlFor="under40">Under £40</label>
          </div>
          <div className="price-option">
            <input
              type="checkbox"
              id="under60"
              checked={priceFilters.under60}
              onChange={() => handlePriceFilterChange('under60')}
            />
            <label htmlFor="under60">Under £60</label>
          </div>
          <div className="price-option">
            <input
              type="checkbox"
              id="above60"
              checked={priceFilters.above60}
              onChange={() => handlePriceFilterChange('above60')}
            />
            <label htmlFor="above60">£60 above</label>
          </div>
        </div>
        <div className="category-filter">
          <h3>Service Category</h3>
          {["🧹 Cleaning", "🛠️ Handyman", "📚 Tutoring", "🐶 Pets", "🪴 Gardening",
            "🚚 Moving", "🚗 Car Related", "💻 IT Set Up", "💆‍♀️ Selfcare", "👶 Baby Sitting", "💡 Others"].map((category) => (
            <div key={category} className="category-option">
              <input
                type="checkbox"
                id={category}
                checked={selectedCategories.includes(category)}
                onChange={() => handleCategoryFilterChange(category)}
              />
              <label htmlFor={category}>{category}</label>
            </div>
          ))}
        </div>
      </div>

      {isMobile && ( 
        <button
          className={`toggle-map-btn ${showMap ? 'map-active' : ''}`}
          onClick={toggleMapView}
        >
          <span className="button-text">{showMap ? 'Back to services' : 'Map view'}</span>
          <img src="/images/map_button.avif" alt="Map button" />
        </button>
      )}
      
      <main className="browse-main">
        {showMap ? (
          isMapLoaded && (
            <div className="map-container">
              <BrowseMap listings={filteredServices} />
            </div>
          )
        ) : (
          renderProductCards()
        )}
      </main>
    </div>
  );
};

export default Browse;